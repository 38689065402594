<template>
  <div class="home">
    <swiper id="swiperBox" :options="swiperOption">
      <swiper-slide class="swiper-slide slide-one">
        <div class="page">
          <h3>时光飞逝</h3>
          <p>Time drops in decay</p>
        </div>
      </swiper-slide>
      <swiper-slide class="swiper-slide slide-two">
        <div class="page">
          <h3>奋斗不止</h3>
          <p>Cease to struggle</p>
        </div>
      </swiper-slide>
      <swiper-slide class="swiper-slide slide-three">
        <div class="page">
          <h3>热爱生活</h3>
          <p>Enjoy my life</p>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
// @ is an alias to /src
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
export default {
  name: "home",
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      swiperOption: {
        loop: true,
        direction: "horizontal", //水平方向移动
        centeredSlides: true,
        grabCursor: true, //鼠标覆盖Swiper时指针会变成手掌形状，拖动时指针会变成抓手形状
        setWrapperSize: true, //Swiper使用flexbox布局(display: flex)，开启这个设定会在Wrapper上添加等于slides相加的宽或高，在对flexbox布局的支持不是很好的浏览器中可能需要用到。
        slidesPerView: 1, //设置slider容器能够同时显示的slides数量(carousel模式)。可以设置为数字（可为小数，小数不可loop），或者 'auto'则自动根据slides的宽度来设定数量。loop模式下如果设置为'auto'还需要设置另外一个参数loopedSlides。
        mousewheel: true, //开启鼠标滚轮控制Swiper切换。可设置鼠标选项，默认值false
        mousewheelControl: true, //同上
        resistanceRatio: 0, //抵抗率。边缘抵抗力的大小比例。值越小抵抗越大越难将slide拖离边缘，0时完全无法拖离。本业务需要
        observeParents: true //将observe应用于Swiper的父元素。当Swiper的父元素变化时，例如window.resize，Swiper更新
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.swiper-slide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  height: 600px;
  .page {
    overflow: hidden;
    height: 256px;
    h3,
    p {
      font-size: 40px;
      font-weight: 400;
      color: #fff;
    }
  }
}

.slide-one {
  background: url(../../../assets/img/home_top.jpg) no-repeat center;
  background-size: cover;
  background-position: bottom;
}
.slide-two {
  background: url(../../../assets/img/home_do.jpg) no-repeat center;
  background-size: cover;
  background-position: bottom;
}
.slide-three {
  background: url(../../../assets/img/home_anli.jpg) no-repeat center;
  background-size: cover;
  background-position: bottom;
}
</style>
