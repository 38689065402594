import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);

import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";
Vue.use(VueAwesomeSwiper);

import Viewer from "v-viewer";
import "viewerjs/dist/viewer.css";
Vue.use(Viewer);

import VueLazyload from "vue-lazyload";
Vue.use(VueLazyload, {
  //完全显示的时候加载
  preLoad: 1,
  //失败时显示的图片
  error: require("../../assets/img/error.png"),
  //加载时显示的GIF图
  loading: require("../../assets/img/loading.gif"),
  //尝试加载几次
  attempt: 1
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
