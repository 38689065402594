<template>
  <div id="app">
    <el-container>
      <el-header>
        <div class="logo">
          <img src="../../assets/img/toplogo.png" alt />
        </div>
        <el-menu
          :default-active="$route.path"
          class="el-menu-demo"
          mode="horizontal"
          :router="router"
        >
          <el-menu-item index="/">首页</el-menu-item>
          <el-menu-item index="/cook">周末小厨</el-menu-item>
        </el-menu>
      </el-header>
      <el-main>
        <router-view />
      </el-main>
    </el-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      router: true,
      defaultActive: "/",
      isShow: false
    };
  }
};
</script>
[]
<style lang="scss">
* {
  padding: 0;
  margin: 0;
}
html,
body {
  width: 100%;
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.el-header {
  display: flex;
  margin: 0 auto;
  padding: 0 !important;
  width: 1240px;
  justify-content: space-between;
  align-content: center;
  .logo {
    img {
      height: 60px;
    }
  }
}
.el-main {
  width: 1240px;
  margin: 0 auto;
  padding: 0 !important;
}
</style>
